import React, { useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import axios from "axios";
import { MdCall, MdLocationPin } from "react-icons/md";
import { FiMail } from "react-icons/fi";

const Consult = () => {
  const privacyRef = useRef();
  const [menu, setMenu] = useState(false);
  const [form, setForm] = useState({});
  const formRef = useRef();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (privacyRef.current.checked) {
      try {
        await axios.post("https://antenne5g.it/consultmail", {
          name: form.name,
          surname: form.surname,
          email: form.email,
          phone: form.phone,
          message: form.message,
        });
        alert("We will get back to you soon!");
        formRef.current.reset();
        setLoading(false);
      } catch (err) {
        console.log(err);
        alert("Please fill the form below!");
        setLoading(false);
      }
    } else {
      alert("Accept the Privacy Policy!");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="bg_title">
        <nav className="d-flex align-items-center Navbar">
          <div className="d-flex justify-content-between align-items-center canvas">
            <Link to="/">
              <LazyLoadImage src={logo} className="logo" alt="antenne5g" />
            </Link>
            <div className="d-flex align-items-center menu">
              {window.matchMedia("(min-width: 800px)").matches && (
                <>
                  <Link to="/" className="nav-link">
                    Fa per te?
                  </Link>
                  <Link to="/" className="nav-link ms-3">
                    Cosa otterrai
                  </Link>
                  <Link to="/" className="nav-link ms-3">
                    Come funziona
                  </Link>
                  <Link to="/segnalatori" className="nav-link ms-3">
                    Segnalatori
                  </Link>
                </>
              )}
              <Link to="/questions" className="btn primary_btn ms-3 px-4">
                TERRENI
              </Link>
              {window.matchMedia("(max-width: 800px").matches && (
                <AiOutlineMenu
                  className="ms-3 text-white"
                  size={30}
                  cursor="pointer"
                  onClick={() => setMenu(!menu)}
                />
              )}
            </div>
          </div>
        </nav>
        {menu && (
          <div className="resp_menu">
            <Link to="/" className="nav-link mt-5 p-2">
              Fa per te?
            </Link>
            <Link to="/" className="nav-link mt-2 p-2">
              Cosa otterrai
            </Link>
            <Link to="/" className="nav-link mt-2 p-2">
              Come funziona
            </Link>
            <Link to="/segnalatori" className="nav-link mt-2 p-2">
              Segnalatori
            </Link>
          </div>
        )}
        <h1 className="mt-5 text-white">Segnalatori Immobiliari</h1>
      </div>

      <div className="mt-5 bg_gray py-5">
        <div className="text-center">
          <h1 className="fw-bold">Contattaci!</h1>
          <p className="mt-1">
            Chiedi informazioni per diventare un nostro collaboratore
            occasionale
          </p>
        </div>

        <form
          className="consult_form bg-white mt-4"
          ref={formRef}
          onSubmit={HandleSubmit}
        >
          <div>
            <label>
              Name <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex nameBox">
              <input
                type={"text"}
                className="form-control rounded-0 me-3 name"
                placeholder="Nome"
                id="name"
                onChange={(e) => handleChange(e)}
              />
              <input
                type={"text"}
                className="form-control rounded-0 name"
                placeholder="Cognome"
                id="surname"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="mt-3">
            <label>
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type={"email"}
              className="form-control rounded-0"
              id="email"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="mt-3">
            <label>
              Telephone number <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type={"number"}
              className="form-control rounded-0"
              id="phone"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="mt-3">
            <label>
              Enter your message <span style={{ color: "red" }}>*</span>
            </label>
            <textarea
              className="form-control rounded-0"
              id="message"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="d-flex align-items-start form-check mt-3">
            <input
              className="form-check-input mb-0"
              type="checkbox"
              id="flexCheckDefault"
              ref={privacyRef}
            />
            <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
              Accetto i termini e le condizioni della Privacy Policy{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
          </div>

          <button
            type="submit"
            className="btn bg_gray py-2 px-3 mt-2 rounded-0 border"
          >
            {loading ? "loading" : "Invia"}
          </button>
        </form>
      </div>

      <footer className="bg_primary py-5">
        <div className="d-flex justify-content-between flex-wrap align-items-start canvas">
          <div style={{ width: "300px" }}>
            <MdLocationPin
              size={40}
              color="white"
              className="d-block mx-auto"
            />
            <p className="mt-2">TECH CONSULTING</p>
            <p>SOCIETA' A RESPONSABILITA' LIMITATA SEMPLIFICATA</p>
            <p>
              Intermediazione servizi di telecomunicazione e trasmissione dati
            </p>
            <p>P.iva 01925980938</p>
          </div>
          <div style={{ width: "300px" }} className="text-center">
            <FiMail size={40} color="white" />
            <p className="mt-2 fs-5">info@antenne5g.it</p>
          </div>
          <div style={{ width: "300px" }} className="text-center">
            <MdCall size={40} color="white" />
            <p className="fs-5">800 987 789</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Consult;
